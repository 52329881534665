export const setBlockedTimer = (document: Document) => {
    const blockedTimer = document.querySelector(".fti-ci-js-blocked-counter")

    if (blockedTimer) {
        const display = blockedTimer as HTMLElement
        const unblockTimeSeconds = Math.floor(new Date().getTime() / 1000 + 3600)
        const interval = setInterval(() => {
            const remainingBlockedSeconds = calculateSecondsFromNowUntil(unblockTimeSeconds)
            if (remainingBlockedSeconds <= 0) {
                display.innerHTML = "00:00"
                clearInterval(interval)
            } else {
                display.innerHTML = formatCountdown(remainingBlockedSeconds)
            }
        }, 1000)
    }

    function calculateSecondsFromNowUntil(untilSeconds: number) {
        return Math.floor(untilSeconds - new Date().getTime() / 1000)
    }

    function formatCountdown(timer: number) {
        return formatCountdownPart(Math.floor(timer / 60)) + ":" + formatCountdownPart(Math.floor(timer % 60))
    }

    function formatCountdownPart(timePart: number) {
        return (timePart < 10 ? `0${timePart}` : timePart) as string
    }
}
